import React from "react";

export const CallIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4167 11.1325L13.7967 10.755C14.3217 10.2333 15.1392 10.1258 15.8108 10.4908L17.4025 11.3567C18.425 11.9125 18.6508 13.275 17.8517 14.0692L16.6683 15.2458C16.3667 15.5458 15.9933 15.7642 15.5633 15.8042C14.57 15.8967 12.5258 15.8467 10.1142 14.435L13.4167 11.1325ZM8.49166 6.23584L8.73082 5.9975C9.31999 5.4125 9.37582 4.4725 8.86166 3.78584L7.81166 2.38334C7.17499 1.53334 5.94582 1.42084 5.21666 2.14584L3.90832 3.44584C3.54749 3.80584 3.30582 4.27084 3.33499 4.7875C3.38916 5.7375 3.71249 7.47084 5.12582 9.60084L8.49166 6.23584Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M10.0525 9.58083C7.59749 7.14 8.48582 6.24083 8.49166 6.235L5.12582 9.60167C5.68166 10.4383 6.40499 11.3358 7.34582 12.2717C8.29582 13.2167 9.22916 13.9175 10.1142 14.435L13.4167 11.1333C13.4167 11.1333 12.515 12.03 10.0525 9.58167"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4167 11.1325L13.7967 10.755C14.3217 10.2333 15.1392 10.1258 15.8109 10.4908L17.4025 11.3567C18.425 11.9125 18.6509 13.275 17.8517 14.0692L16.6684 15.2458C16.3667 15.5458 15.9934 15.7642 15.5634 15.8042C14.57 15.8967 12.5259 15.8467 10.1142 14.435L13.4167 11.1325ZM8.49169 6.23584L8.73085 5.9975C9.32002 5.4125 9.37585 4.4725 8.86169 3.78584L7.81169 2.38334C7.17502 1.53334 5.94586 1.42084 5.21669 2.14584L3.90836 3.44584C3.54752 3.80584 3.30585 4.27084 3.33502 4.7875C3.38919 5.7375 3.71252 7.47084 5.12586 9.60084L8.49169 6.23584Z"
        fill="#1a8aff"
      />
      <path
        opacity="0.6"
        d="M10.0525 9.58083C7.59752 7.14 8.48585 6.24083 8.49169 6.235L5.12585 9.60167C5.68169 10.4383 6.40502 11.3358 7.34585 12.2717C8.29585 13.2167 9.22919 13.9175 10.1142 14.435L13.4167 11.1333C13.4167 11.1333 12.515 12.03 10.0525 9.58167"
        fill="#1a8aff"
      />
    </svg>
  );
};
