export const BASEURL2 = "https://hammerhead-app-p5uvi.ondigitalocean.app/";
export const ANALYTICS_KEY = "SCK_7CF9F85A33FF0A188FCD8B";
export const COLLECTION_NAME = "iaykuxwnaz";
export const INDEX_NAME = "slfbuerqnw";
export const ASSISTANT_ID = "AST_E45E4ABC55A69CC";
export const SAVE_CHAT =
  "https://squid-app-2-bcuud.ondigitalocean.app/v1/api/chatbots/save-log";

export const META_DATA = {
  assistant_id: ASSISTANT_ID,
};

export const DEPT = [
  {
    name: "Customer Service",
    phone: "+2348110248204",
    email: "sotom.horsfall@analyticsintelligence.com",
  },
  {
    name: "Customer Service",
    phone: "07032825506",
    email: "enquiry@myacademy.ai",
  },
  {
    name: "Customer Service",
    phone: "07032825506",
    email: "fatimah@myacademy.ai",
  },
  {
    name: "Customer Service",
    phone: "07032825506",
    email: "lara@myacademy.ai",
  },
];
