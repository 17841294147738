import React from "react";

const GlobeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_707_3912)">
        <path
          d="M1.33333 7.99998C1.33333 11.682 4.31799 14.6666 7.99999 14.6666C11.682 14.6666 14.6667 11.682 14.6667 7.99998C14.6667 4.31798 11.682 1.33331 7.99999 1.33331C4.31799 1.33331 1.33333 4.31798 1.33333 7.99998Z"
          stroke="#1a8aff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.66666 1.36664C8.66666 1.36664 10.6667 3.99997 10.6667 7.99997C10.6667 12 8.66666 14.6333 8.66666 14.6333M7.33333 14.6333C7.33333 14.6333 5.33333 12 5.33333 7.99997C5.33333 3.99997 7.33333 1.36664 7.33333 1.36664M1.75333 10.3333H14.2467M1.75333 5.66664H14.2467"
          stroke="#1a8aff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_707_3912">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GlobeIcon;
