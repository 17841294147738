"use client";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import ReactMarkdown from "react-markdown";
import { l, prefilledQuestions } from "./constants";
import { aiChat, arrow_down, chatbot, close, minimize } from "../../assets";
import {
  CallIcon,
  ChatIcon,
  DislikeIcon,
  LikeIcon,
  SendIcon,
} from "../../icons";
import { fetcher } from "../../utils/fetcher";
import { BASEURL2, DEPT, META_DATA, SAVE_CHAT } from "../../utils/constants";
import ConfirmationModal from "./confirmationModal";
import ChatBotForm from "./chatBotForm";
import { setDate } from "date-fns";
import FeedbackForm from "./feedbackForm";
import LangusgeSwitcher from "./languageSwitcher";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { encryptData } from "../../utils/security";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showLikedFeedback, setShowLikedFeedback] = useState(false);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showFeedbackForm, setShowFeedbackForm] = useState(null);
  const [currentLikedMessage, setCurrentLikedMessage] = useState(null);
  const [lang, setLang] = useState("english");
  const [isScrollable, setIsScrollable] = useState(false);
  const containerRef = useRef(null);
  const { pathname } = useLocation("");
  const [assistantData, setAssistantData] = useState(null)
  const isMobile = window.innerWidth <= 768;
  const saveChat = async (d) => {
    const data = encryptData({
      organizationId: "64f6dce3983a07f7e02210e6",
      client: META_DATA.assistant_id,
      conversationTitle: `Conversation-${d.followup_id}`,
      conversationId: d.followup_id,
      data: d,
    });

    try {
      const j = await fetcher(SAVE_CHAT, {
        method: "POST",
        body: JSON.stringify({ encryptedData: data }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Process the response data
    } catch (error) {
      console.log(error);

      // Handle errors, including token expiration
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    const form = new FormData(e.target);
    const formObject = Object.fromEntries(form);
    const payload = {
      ...formObject,
      dept: DEPT,
      phone: phone,
      date: startDate,
      time: startDate,
      country_region: country,
    };

    try {
      await fetcher(`${BASEURL2}chat/chatbot/request`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      setFormSubmitted(true);
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });

      setIsScrollable(false);
    }
  };

  const handleSend = async (que) => {
    const question = inputValue.trim() ? inputValue.trim() : que;
    if (question) {
      const newMessage = { sender: "user", text: question };
      setMessages([...messages, newMessage]);
      setInputValue("");
      setLoading(true);
      scrollToBottom();
      const response = await onSubmitAssistant(question);

      setLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          text: response?.message || "An error occurred.",
          feedback: null,
        },
      ]);
      scrollToBottom();
      setTimeout(() => {
        if (response?.is_form) setAction("call");
      }, 2000);
    }
  };

  const handleFeedback = async (index, feedbackType) => {
    setMessages((prevMessages) =>
      prevMessages?.map((message, i) =>
        i === index ? { ...message, feedback: feedbackType } : message
      )
    );

    if (feedbackType === "liked") {
      setCurrentLikedMessage(index);
      setShowLikedFeedback(true);

      setTimeout(() => {
        setShowLikedFeedback(false);
      }, 5000);
    }

    if (feedbackType === "disliked") {
      setShowFeedbackForm(index);
    }

    const payload = {
      followup_id: followUp,
      note: [feedbackType],
      rating: feedbackType === "liked" ? 1 : 0,
    };

    await fetcher(`${BASEURL2}chat/chatbot/feedback`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const handleFeedbackSubmit = async (feedback) => {
    const feedbacks = [...feedback.reasons, feedback?.additionalFeedback];
    const payload = {
      followup_id: followUp,
      note: feedbacks,
      rating: 0,
    };

    await fetcher(`${BASEURL2}chat/chatbot/feedback`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  const handleFetchAssistant = async () => {
    const payload = {
      assistant_id: [META_DATA.assistant_id],
    };

    try {
      const res = await fetcher(`${BASEURL2}assistant`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAssistantData(res[0])
    } catch (err) {
      console.log(err);
      
    }
  };
  const onSubmitAssistant = async (question) => {
    if (question.length < 1) return;
    try {
      const payload = {
        query: question,
        followup_id: followUp,
        language: lang,
        collection_name: assistantData.chat_assistant_collection_id,
        index_name: assistantData.chat_assistant_index_id,
        ...META_DATA,
      };

      const fetchResponse = await fetcher(
        `${BASEURL2}assistant/v2/chat/chatbot`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (fetchResponse.failure) throw JSON.parse(fetchResponse.message).error;
      saveChat(fetchResponse);
      setFollowUp(fetchResponse?.followup_id);

      return fetchResponse?.is_form
        ? {
            message: l[lang]?.infoTexts?.scheduleMessage,
            is_form: true,
          }
        : { message: fetchResponse.response, is_form: false };
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCloseChat = () => {
    setShowModal(true);
  };

  const confirmCloseChat = () => {
    toggleChat();
    setMessages([]);
    setAction("");
    setPhone("");
    setCountry("");
    setDate("");
    setShowModal(false);
    setSubmitting(false);
  };

  const cancelCloseChat = () => {
    setShowModal(false);
  };

  const checkIfScrollable = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const atBottom = scrollTop >= scrollHeight - clientHeight - 1;
      setIsScrollable(!atBottom);
    }
  };

  useEffect(() => {
    checkIfScrollable();

    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const atBottom = scrollTop >= scrollHeight - clientHeight - 1;

        setIsScrollable(!atBottom);
      }
    };

    const currentContainerRef = containerRef.current;
    if (currentContainerRef) {
      currentContainerRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, messages.length, isScrollable, inputValue]);

  // const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLikedFeedback(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [showLikedFeedback]);
  useMemo(() => {
    handleFetchAssistant()
  }, [])
  const ChatWindow = (
    <div className="chat-window">
      <div className="chat-header">
        <LangusgeSwitcher lang={lang} setLang={setLang} />
        {!action ? (
          <h1 className="pba-header font-inter">Powered by [AI]</h1>
        ) : (
          <div className={action ? "flex gap-[10px]" : "hidden"}>
            <button
              className={`p-1 ${
                action === "chat" ? "bg-primary rounded-[4px]" : ""
              }`}
              onClick={() => setAction("chat")}
            >
              <ChatIcon active={action === "chat"} />
            </button>
            <button
              className={`p-1 ${
                action === "call" ? "bg-primary rounded-[4px]" : ""
              }`}
              onClick={() => setAction("call")}
            >
              <CallIcon active={action === "call"} />
            </button>
          </div>
        )}
        <div className="flex gap-2 items-center">
          <button onClick={toggleChat} className="cursor-pointer">
            <img src={minimize} alt="-" />
          </button>
          <button onClick={handleCloseChat} className="cursor-pointer">
            <img src={close} alt="x" />
          </button>
        </div>
      </div>
      <div className={messages?.length ? "" : "mt-5 mx-auto"}>
        {action === "call"
          ? !formSubmitted && (
              <h1 className="ai-customer-rep">
                {l[lang]?.infoTexts?.customerCareRep}
              </h1>
            )
          : messages?.length === 0 && <img src={aiChat} alt="[AI} Chat" />}
      </div>
      <div className="chat-intro">
        {!action && (
          <>
            <h1 className="chat-intro-message">
              {l[lang]?.infoTexts?.moreInfo}
            </h1>

            <div className="grid grid-cols-2  gap-[16px] max-w-[398px] mx-auto">
              {l[lang]?.actions?.map((action) => (
                <button
                  onClick={() => setAction(action.action)}
                  className="action-button"
                  key={action.action}
                >
                  <img src={action.icon} alt={action.action} />
                  <span>{action.desc}</span>
                </button>
              ))}
            </div>
          </>
        )}
        {action === "chat" && messages?.length === 0 && (
          <div className="prefilled-questions">
            {prefilledQuestions(pathname, lang)?.map((que) => (
              <button
                className="prefilled-que"
                key={que}
                onClick={() => {
                  handleSend(que);
                }}
              >
                {que}
              </button>
            ))}
          </div>
        )}

        {action === "call" && (
          <>
            {!formSubmitted && (
              <h1 className="chat-intro-message">
                {l[lang]?.infoTexts?.scheduleCall}
              </h1>
            )}
            <ChatBotForm
              submitted={formSubmitted}
              handleSubmit={handleFormSubmit}
              reset={setFormSubmitted}
              submitting={submitting}
              phone={phone}
              setPhone={setPhone}
              setCountry={setCountry}
              startDate={startDate}
              setStartDate={setStartDate}
              language={lang}
              toggle={() => setAction("chat")}
            />
          </>
        )}
      </div>
      {action === "chat" && messages?.length > 0 && (
        <div className="chat-content w-full">
          <div ref={containerRef} className="messages">
            {messages?.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.sender === "bot" ? (
                  <>
                    <ReactMarkdown className="markdown">
                      {message.text}
                    </ReactMarkdown>
                    <div className="like-dislike">
                      <button
                        onClick={() => handleFeedback(index, "liked")}
                        disabled={message.feedback === "liked"}
                      >
                        <LikeIcon active={message.feedback === "liked"} />
                      </button>
                      <button
                        onClick={() => handleFeedback(index, "disliked")}
                        disabled={message.feedback === "disliked"}
                      >
                        <DislikeIcon active={message.feedback === "disliked"} />
                      </button>
                    </div>

                    {/* Show thank you message only if liked */}
                    {message.feedback === "liked" &&
                      index === currentLikedMessage && (
                        <p
                          className={
                            showLikedFeedback ? "block feedback-msg" : "hidden"
                          }
                        >
                          {l[lang]?.infoTexts?.feedbackThankYou}{" "}
                        </p>
                      )}

                    {/* Show feedback form only if disliked */}
                    {message.feedback === "disliked" &&
                      index === showFeedbackForm && (
                        <FeedbackForm
                          onSubmit={handleFeedbackSubmit}
                          setShowFeedback={setShowFeedbackForm}
                          language={lang}
                        />
                      )}
                  </>
                ) : (
                  message.text
                )}
              </div>
            ))}
            {loading && (
              <div className=" loading">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            )}
          </div>
          {messages.length > 1 && isScrollable && (
            <button
              className="sticky arrow-down mx-auto"
              onClick={scrollToBottom}
            >
              <img src={arrow_down} alt="arrow down" />
            </button>
          )}
        </div>
      )}
      {action === "chat" && (
        <div className="input-area">
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Ask a question"
            rows={1}
            onKeyPress={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleSend();
              }
            }}
          ></textarea>
          <button onClick={handleSend} disabled={!inputValue.trim()}>
            <SendIcon />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="chatbot-container">
        {!isOpen && (
          <div className="cursor-pointer" onClick={toggleChat}>
            <img src={chatbot} alt="Chat Icon" />
          </div>
        )}
      </div>
      {isMobile ? (
        <div className="chatbot-container">
          {isOpen && ChatWindow}
          {showModal && (
            <ConfirmationModal
              onConfirm={confirmCloseChat}
              onCancel={cancelCloseChat}
            />
          )}
        </div>
      ) : (
        <Draggable>
          <div className="chatbot-container">
            {isOpen && ChatWindow}
            {showModal && (
              <ConfirmationModal
                onConfirm={confirmCloseChat}
                onCancel={cancelCloseChat}
              />
            )}
          </div>
        </Draggable>
      )}
    </>
    // <div className="chatbot-container">
    //   {!isOpen && (
    //     <div className="cursor-pointer" onClick={toggleChat}>
    //       <img src={chatbot} alt="Chat Icon" />
    //     </div>
    //   )}
    //   {/* {isOpen && (isMobile ? ChatWindow : <Draggable>{ChatWindow}</Draggable>)} */}
    //   {isOpen && ChatWindow}
    //   {showModal && (
    //     <ConfirmationModal
    //       onConfirm={confirmCloseChat}
    //       onCancel={cancelCloseChat}
    //     />
    //   )}
    // </div>
  );
};

export default Chatbot;
