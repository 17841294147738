const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="modala-overlay">
      <div className="modala">
        <h2>Are you sure?</h2>
        <p>Your chat will be lost if you close it now.</p>
        <div className="modala-actions">
          <button onClick={onConfirm} className="confirm-btn">
            Yes, Close
          </button>
          <button onClick={onCancel} className="cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
