import React, { useState } from 'react'
const data = [
  {
    title: "Analytics Help Desk",
    body: "The [AI] Data Assistant platform is your first-line analytics support solution; it will take initial reporting questions and what it can’t answer will pass on to an Analyst on your team.",
  },
  {
    title: "Analytics Support Ticketing",
    body: "A dedicated service desk solution for digital marketing and analytics requests in the business. This gives your dedicated Analytics team greater control in managing requests for insight and reporting. ",
  },
  {
    title: "Single Analytics’ Solution",
    body: "Instead of relying on several platforms to gather your digital marketing analytics data, you can now use one single tool to intelligently query and access your data. With integrations into a wide range of digital analytics tools like Adobe Analytics, Google Analytics, Tealium AudienceStream, ObservePoint, BigQuery, AWS S3 and many others. ",
  },
  {
    title: "Data Access for Everyone",
    body: "Imagine an [AI] DATA Assistant assigned and dedicated to every user in your organization, focused on each user’s goals and KPIs, working as a collective force supporting teams and departments targets. This is a key benefit the [AI] DATA assistant gives a company data insight to everyone encouraging a culture for data-driven decision making. ",
  },

  {
    title: "Powerful Conversational Engine",
    body: "[AI] DATA Assistant is developed on a powerful conversation platform that allows users to simply ask their DATA Assistant questions and get answers in plain English. This makes relevant and timely information more accessible even to users who are less familiar with BI or analytics tools.",
  },
  {
    title: "Easily Share Insight Across the Organization",
    body: "Insights identified by your [AI] DATA Assistant can be shared with individual colleagues or with the entire team or department. This ensures everyone is focused on the same that is used to drive informed decision making across the organization.",
  },
  {
    title: "Analytics Training as you use",
    body: "Educate your organization on the use of data and analytics in decision making to be more data-driven. Training mode can be enabled on your [AI] DATA Assistant to teach users the fundamentals of data analysis from the beginner stage through to the advanced stage.",
  },
  {
    title: "Maximized Data Sources",
    body: "With [AI] DATA Assistant, businesses can connect their data sources (online and offline data) and enable users to search for and access information from any one of them directly from the platform. This lets companies give their users fast access to information any time they need them through the interface, email, Slack or voice commands.",
  },
  {
    title: "Data Governance and Access Control",
    body: "To ensure that only those with the proper authorizations can view sensitive company data, [AI] DATA Assistant allows administrators to stringently control who has access to which rows and columns and sections of data. On top of that, the solution also protects search suggestions according to these settings.",
  },
  {
    title: "Personalized Delivery",
    body: `Different customers have different usage patterns that inform how they prefer to receive notifications. Users in the [AI] DATA Assistant can determine the best channel—email, text, Slack, or in-app notification with the highest likelihood of getting the customer’s attention.`,
  },
];
export default function Feature() {
  const [currentTab, setCurrentTab] = useState("");
  return (
    <div className='features container is-max-widescreen'>
      <div className='header'>
        <h1 className='header-text'>Features of <span>DATA</span> Assistant</h1>
        <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1690964639/laptop_dbobji.png" alt="laptop" />
      </div>
      <div className="container is-max-widescreen p-2">
        <div className="faqs">
          {data.map((d) => (
            <div
              className="expandable"
              key={d.title}
              onClick={() => {
                if (currentTab === d.title) {
                  return setCurrentTab("");
                }
                setCurrentTab(d.title);
              }}
            >
              <div className="expandable-header">
                <p className="blue-text has-text-weight-bold">{d.title}</p>
                <p className="icon">
                  <i
                    className={`${
                      currentTab === d.title
                        ? "fa-solid fa-minus"
                        : "fa-solid fa-plus"
                    } orange-text`}
                  ></i>
                </p>
              </div>
              <p
                className={` ${
                  currentTab === d.title ? "expand" : ""
                } expandable-body`}
              >
                {d.body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <style jsx='true'>
        {`
          .feature{
            display:flex;
            flex-direction:row;
            align-items:center;
            gap:20px;
          }
          .number{
            background:transparent;
          }
          .feature span{
            min-width:79px;
            max-width:79px;
            height:79px;
            border-radius:50%;
            background:#1a8aff;
            display:flex;
            align-items:center;
            justify-content:center;
            color:#fff;
            font-size:40px;
          }
          .feature-content{
            width:80%;
          }
          .features-list{
            display:flex;
            flex-direction:column;
            gap:88px;
          }
          .features{
            margin-top: 100px;
            margin-bottom: 100px;
            display:flex;
            flex-direction:column;
            gap:96px;
          }
          .header{
            display:flex;
            flex-direction: column;
            gap: 44px;
            align-items:center
          }
          .header-text{
            color:#2F4171;
            text-align:center
          }
          .header-text span{
            color:#1a8aff
          }
          .tip {
            padding: 1rem;
          }
          .price-list li::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: 1px;
            top: 2px;
            height: 15px;
            width: 25px;
            background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1675685567/Check_ivrwsx.png");
            background-position: left 5px;
            background-repeat: no-repeat;
            background-size: 15px;
          }
          .price-list li {
            padding-bottom: 1rem;
            padding-left: 25px;
            position: relative;
          }
          .choose {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 2rem;
            justify-content: space-between;
          }
  
          .choose-card {
            background-color: #fff;
            border-radius: 20px;
            padding: 2rem;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
          }
          .choose-card p {
            font-size: 0.9rem;
          }
  
          .choose-card.growth {
            background-color: var(--main-orange);
            color: #fff;
          }
          .expandable-body {
            height: 0px;
            overflow: hidden;
            transition: 0.3s ease;
            margin-bottom: 1rem;
            line-height: 1.6;
          }
          .expandable-body.expand {
            height: auto;
            margin-top: 0.8rem;
          }
          .expandable {
            transition: 0.3s ease;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
          }
          .expandable p {
            font-size: 0.9rem;
          }
          .expandable-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
  
            padding-top: 1rem;
          }
          .home {
            margin-top: 65px;
          }
          .expandable-header > div {
            flex-basis: 100px;
          }
          .expandable-header .icon {
            margin-left: 1rem;
            flex-shrink: 0;
          }
        `}
      </style>
    </div>
  )
}
