import React, { useEffect, useState } from "react";
import { l } from "./constants";

const FeedbackForm = ({ onSubmit, setShowFeedback, language }) => {
  const [feedback, setFeedback] = useState({
    reasons: [],
    additionalFeedback: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setFeedback((prevFeedback) => {
      const reasons = prevFeedback.reasons.includes(value)
        ? prevFeedback.reasons.filter((reason) => reason !== value)
        : [...prevFeedback.reasons, value];
      return { ...prevFeedback, reasons };
    });
  };

  const handleTextChange = (event) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      additionalFeedback: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    await onSubmit(feedback);
    setSubmitting(false);
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setShowFeedback(null);
        setSubmitted(false);
      }, 5000);
    }
  }, [submitted]);

  return (
    <div className="flex gap-[10px]  items-start">
      {!submitted && (
        <form
          onSubmit={handleSubmit}
          className="w-[317px] max-w-full px-3 py-[10px] rounded-[4px] bg-primary"
        >
          <div className=" text-[12px] text-[rgba(255,255,255,0.8)]">
            <label className="text-[12px] text-[rgba(255,255,255,0.8)]">
              Why did you choose this rating?
            </label>
            <div className="space-y-2 mt-2">
              <div>
                <input
                  type="checkbox"
                  id="wrongFormat"
                  value="Wrong format"
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor="wrongFormat">Wrong format</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="notAccurate"
                  value="Not accurate"
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor="notAccurate">Not accurate</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="notUseful"
                  value="Not useful or incomplete"
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor="notUseful">Not useful or incomplete</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="other"
                  value="Other"
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
          </div>

          <textarea
            className="w-full bg-white text-black rounded-[6px] p-[6px] text-[12px] mt-[10px]"
            value={feedback.additionalFeedback}
            onChange={handleTextChange}
            rows={5}
            placeholder="Provide additional feedback - optional"
          ></textarea>

          <p className="text-[8px] font-[600] text-[rgba(255,255,255,0.8)] mb-4">
            Do not enter personal, confidential, or sensitive information.
          </p>

          <div className="flex gap-[20px]">
            <button
              type="button"
              onClick={() => setShowFeedback(null)}
              className="px-6 py-2 text-[12px] text-[rgba(255,255,255,0.8)] text-cente flex-grow-0r"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 text-[12px] text-[#282828] rounded bg-white w-max flex-grow-0"
            >
              {submitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      )}

      {submitted && (
        <p className="p-3 bg-primary text-[rgba(255,255,255,0.8)]">
          {l[language]?.infoTexts?.feedbackThankYou}
        </p>
      )}
    </div>
  );
};

export default FeedbackForm;
