import React from "react";

export const ChatIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33334 15H5.00001V18.4008L9.25084 15H13.3333C14.2525 15 15 14.2525 15 13.3333V6.66667C15 5.7475 14.2525 5 13.3333 5H3.33334C2.41417 5 1.66667 5.7475 1.66667 6.66667V13.3333C1.66667 14.2525 2.41417 15 3.33334 15Z"
        fill="white"
      />
      <path
        d="M16.6667 1.66666H6.66667C5.7475 1.66666 5 2.41416 5 3.33333H15C15.9192 3.33333 16.6667 4.08083 16.6667 5V11.6667C17.5858 11.6667 18.3333 10.9192 18.3333 10V3.33333C18.3333 2.41416 17.5858 1.66666 16.6667 1.66666Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33332 15H4.99999V18.4008L9.25082 15H13.3333C14.2525 15 15 14.2525 15 13.3333V6.66667C15 5.7475 14.2525 5 13.3333 5H3.33332C2.41416 5 1.66666 5.7475 1.66666 6.66667V13.3333C1.66666 14.2525 2.41416 15 3.33332 15Z"
        fill="#1a8aff"
      />
      <path
        d="M16.6667 1.66666H6.66667C5.7475 1.66666 5 2.41416 5 3.33333H15C15.9192 3.33333 16.6667 4.08083 16.6667 5V11.6667C17.5858 11.6667 18.3333 10.9192 18.3333 10V3.33333C18.3333 2.41416 17.5858 1.66666 16.6667 1.66666Z"
        fill="#1a8aff"
      />
    </svg>
  );
};
