import { call, chat } from "../../assets";

export const actions = [
  {
    action: "chat",
    desc: "Instant message with our help assistant [AI]Chat",
    icon: chat,
  },
  {
    action: "call",
    desc: "Schedule a Call with a customer care Representative",
    icon: call,
  },
];

export const countries = [
  "Country",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const languages = [
  {
    label: "en",
    value: "english",
  },
  {
    label: "es",
    value: "spanish",
  },
  {
    label: "fr",
    value: "french",
  },
  {
    label: "ar",
    value: "arabic",
  },
];

// export const languages = [
//   {
//     label: "en",
//     value: "english",
//   },
//   {
//     label: "pd",
//     value: "pidgin",
//   },
//   {
//     label: "fr",
//     value: "french",
//   },
//   {
//     label: "ig",
//     value: "igbo",
//   },
//   {
//     label: "yr",
//     value: "yoruba",
//   },
//   {
//     label: "ha",
//     value: "hausa",
//   },
//   {
//     label: "sw",
//     value: "swahili",
//   },
//   {
//     label: "ar",
//     value: "arabic",
//   },
// ];

// export const languageMap = {
//   english: "en-US", // English
//   pidgin: "en-NG", // Pidgin (fallback to Nigerian English, as there is no native support)
//   french: "fr-FR", // French
//   igbo: "ig-NG", // Igbo
//   yoruba: "yo-NG", // Yoruba
//   hausa: "ha", // Hausa
//   swahili: "sw", // Swahili
//   arabic: "ar-SA", // Arabic
// };

export const timezones = ["Time zone", "UTC", "GMT", "PST", "WAT"];

// English Translations (default)
export const english = {
  questions: [
    "What is Data Assistant and how does it help optimize marketing strategies?",
    "Can Data Assistant integrate with analytics tools like Google Analytics and Power BI?",
    "How can users receive real-time notifications about KPI performance drops?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Instant message with our help assistant [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Schedule a Call with a customer care Representative",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "For more info on our product offerings, engage with [AI] Chat, or speak to a customer care representative.",
    scheduleCall:
      "For specific requests, kindly fill out this form to schedule a call with a customer care representative.",
    customerCareRep: "[AI] Customer Care Representative",
    scheduleMessage: "Please schedule a call with our customer representative.",
    feedbackThankYou: "Thank you for your feedback.",
    callRequestSuccess:
      "Call Request Successful, check your email for the call details.",
  },
};

// Spanish Translations
export const spanish = {
  questions: [
    "¿Qué es Data Assistant y cómo ayuda a optimizar las estrategias de marketing?",
    "¿Puede Data Assistant integrarse con herramientas de análisis como Google Analytics y Power BI?",
    "¿Cómo pueden los usuarios recibir notificaciones en tiempo real sobre la caída del rendimiento de los KPI?",
  ],
  aiPlatformQuestions: [
    "¿Cómo integra datos la IA?",
    "¿Qué habilidades mejoran el asistente de IA?",
    "¿Cómo funciona el monitoreo de KPI?",
  ],
  dataAssistantQuestions: [
    "¿Qué hace el Asistente de Datos?",
    "¿Cómo funciona el motor de análisis?",
    "¿Qué es el modelo de lenguaje de datos?",
  ],
  pllmQuestions: [
    "¿Cómo asegura PLLM los datos?",
    "¿Cómo mejora DataSyntax la privacidad?",
    "¿Qué representa DataSyntax?",
  ],
  retailQuestions: [
    "¿Cómo impulsa la IA el marketing minorista?",
    "¿Qué información se obtiene de los datos de los clientes?",
    "¿Cómo se segmentan y se dirigen los clientes?",
  ],
  fintechQuestions: [
    "¿Cómo mejora la IA los servicios financieros?",
    "¿Qué tareas se pueden automatizar en fintech?",
    "¿Cómo mejora la toma de decisiones la plataforma?",
  ],
  marketingQuestions: [
    "¿Cómo mejora la IA las estrategias de marketing?",
    "¿Qué alertas se pueden configurar para el rendimiento de la campaña?",
    "¿Cómo se comparten los conocimientos dentro del equipo?",
  ],
  digitalAnalyticsQuestions: [
    "¿Cómo optimiza la IA el rendimiento digital?",
    "¿Qué se incluye en los informes de información?",
    "¿Cómo garantizamos la precisión de los datos?",
  ],
  digitalAnalyticsTrainingQuestions: [
    "¿Qué cubre la formación en análisis digital?",
    "¿Cómo puedo dominar Adobe Analytics?",
    "¿Qué habilidades se enseñan en la ingeniería de prompts?",
  ],
  aboutUsQuestions: [
    "¿Cuál es la misión de AI Analytics Intelligence?",
    "¿Cómo comenzó AI Analytics Intelligence?",
    "¿Qué valores fundamentales guían AI Analytics Intelligence?",
  ],
  privacyPolicyQuestions: [
    "¿Cómo se recopila y utiliza la información personal?",
    "¿Qué medidas de seguridad protegen la información personal?",
    "¿Cómo cumple AI Analytics con las regulaciones de privacidad?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Mensaje instantáneo con nuestro asistente de ayuda [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Programar una llamada con un representante de atención al cliente",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "Para obtener más información sobre nuestras ofertas de productos, interactúe con [AI] Chat o hable con un representante de atención al cliente.",
    scheduleCall:
      "Para solicitudes específicas, complete este formulario para programar una llamada con un representante de atención al cliente.",
    customerCareRep: "[AI] Representante de atención al cliente",
    scheduleMessage:
      "Por favor, programe una llamada con nuestro representante de atención al cliente.",
    feedbackThankYou: "Gracias por sus comentarios.",
    callRequestSuccess:
      "Solicitud de llamada exitosa, revise su correo electrónico para los detalles de la llamada.",
  },
};

// French Translations
export const french = {
  questions: [
    "Qu'est-ce que Data Assistant et comment aide-t-il à optimiser les stratégies marketing ?",
    "Data Assistant peut-il s'intégrer avec des outils d'analyse comme Google Analytics et Power BI ?",
    "Comment les utilisateurs peuvent-ils recevoir des notifications en temps réel concernant la baisse de performance des KPI ?",
  ],
  actions: [
    {
      action: "chat",
      desc: "Message instantané avec notre assistant d'aide [AI]Chat",
      icon: chat,
    },
    {
      action: "call",
      desc: "Planifier un appel avec un représentant du service client",
      icon: call,
    },
  ],
  infoTexts: {
    moreInfo:
      "Pour plus d'informations sur nos offres de produits, engagez-vous avec [AI] Chat ou parlez à un représentant du service client.",
    scheduleCall:
      "Pour des demandes spécifiques, veuillez remplir ce formulaire pour planifier un appel avec un représentant du service client.",
    customerCareRep: "[AI] Représentant du service client",
    scheduleMessage:
      "Veuillez planifier un appel avec notre représentant client.",
    feedbackThankYou: "Merci pour vos commentaires.",
    callRequestSuccess:
      "Demande d'appel réussie, consultez votre e-mail pour les détails de l'appel.",
  },
};

// Arabic Translations
export const arabic = {
  questions: [
    "ما هو Data Assistant وكيف يساعد في تحسين استراتيجيات التسويق؟",
    "هل يمكن لـ Data Assistant التكامل مع أدوات التحليل مثل Google Analytics وPower BI؟",
    "كيف يمكن للمستخدمين تلقي إشعارات في الوقت الفعلي حول انخفاض أداء مؤشرات الأداء الرئيسية (KPIs)؟",
  ],
  actions: [
    { action: "chat", desc: "رسالة فورية مع مساعدنا [AI]Chat", icon: chat },
    { action: "call", desc: "جدولة مكالمة مع ممثل رعاية العملاء", icon: call },
  ],
  infoTexts: {
    moreInfo:
      "لمزيد من المعلومات حول عروض منتجاتنا، تواصل مع [AI] Chat، أو تحدث إلى ممثل رعاية العملاء.",
    scheduleCall:
      "للطلبات المحددة، يرجى ملء هذا النموذج لتحديد موعد مكالمة مع ممثل رعاية العملاء.",
    customerCareRep: "[AI] ممثل رعاية العملاء",
    scheduleMessage: "يرجى تحديد موعد مكالمة مع ممثل خدمة العملاء.",
    feedbackThankYou: "شكرًا لك على ملاحظاتك.",
    callRequestSuccess:
      "تم طلب المكالمة بنجاح، يرجى التحقق من بريدك الإلكتروني للحصول على تفاصيل المكالمة.",
  },
};

export const l = {
  arabic,
  english,
  french,
  spanish,
};

export const prefilledQuestions = (pathname, langKey) => {
  const lang = l[langKey];

  if (!lang) {
    console.error(`Language ${langKey} not found!`);
    return [];
  }

  return lang?.questions;
};
