import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const data = [
  {
    title: "Digital Analytics Manager – E-Commerce Company",
    body: '"The Data Assistant has been a game-changer for us in how we manage digital analytics requests from our Digital Marketing teams. The speed and accuracy of the AI in responding to marketing analytics queries has allowed the Data and Analytics teams to focus on more deep-dive, qualitative analysis tasks." ',
  },
  {
    title: "Conversion Rate Optimization Specialist – Insurance Company",
    body: '"The Data Assistant has saved us countless hours of manual data analysis and provided us with actionable insights in real-time. This has allowed us to quickly optimize our campaigns and improve our conversion rates." ',
  },
  {
    title: "Marketing Coordinator/Specialist – Parcel Delivery Company",
    body: `"I'm able to track KPIs in real-time, allowing me to make data-driven decisions on the fly. The AI has been a game-changer for my marketing efforts."`,
  },

  {
    title: "Campaign Manager – Digital Advertising Agency",
    body: '"With the Data Assistant, I no longer have to spend hours pouring over spreadsheets. The AI gives me quick and accurate insights into my campaign\'s performance, freeing up my time for more creative tasks."',
  },

  {
    title: "Senior Marketing Manager – Global Car Brand ",
    body: '"As a Marketer I\'ve always struggled with data analysis from tools like Adobe Analytics, but the Data Assistant makes it so easy! The AI provides me with quick answers to my questions, actionable insights, which has helped me improve my campaigns and achieve my goals."',
  },

  {
    title: "Director of Digital Marketing – Lead Generation Company",
    body: '"I was skeptical at first, but the Data Assistant has completely transformed my approach to marketing analytics. The AI has changed my Digital Marketing teams works, they are quicker at identifying trends and optimizing their campaigns for better results."',
  },
  {
    title: "Senior Marketing Manager – Fintech Company",
    body: '"The Data Assistant is like having my own personal analytics expert! The AI helps me understand my data in a way that makes sense and provides me with recommendations for improvement. It\'s made a huge difference in my digital marketing efforts."',
  },
];
export default function Home() {
  return (
    <main className="home">
      <div className="">
        <div className="container is-max-widescreen">
          <div className="second">
            <div className="blue-box mb-5 second-first">
              <h1 className="mb-5 has-text-weight-bold blue-text">
                Empower your <br />{" "}
                <span className="orange-text">Digital Marketing</span> <br />{" "}
                Team with AI
              </h1>
              <p className="pretrained mb-5">
                Transform your digital marketing with the power of data-driven
                insights by leveraging advanced AI technology from a
                conversational [AI] Data Assistant.
              </p>
              <a href="/demo" className="mb-4">
                <button
                  className="spread-button orange-bg"
                  style={{
                    color: "#fff",
                  }}
                >
                  Request a demo
                </button>
              </a>
            </div>
            <div className="second-second">
              <img
                className="sprawl"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675677641/Hero_Main_Latest_1_dcflms.png"
                alt="busy guy on laptop"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "10vh" }}></div>
      <div className="blue-bg">
        <div className="container is-max-widescreen">
          <div className="second mb-5">
            <div className="second-first mb-5" style={{ flexGrow: 1 }}>
              <h2 className="mb-5 has-text-weight-bold blue-text">
                Get A Dedicated
                <br />
                Marketing Analytics Team
                <br />
                <span className="orange-text">Powered by AI</span>
              </h2>
              <p className=" mb-5">
                With your [AI] Data Assistant constantly monitoring your
                campaign performance, you can quickly identify underperforming
                campaigns and focus on making informed decisions.
              </p>
            </div>
            <div className="second-second">
              <img
                style={{
                  maxWidth: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675933943/Chat_Bubble_Redesign_1_bovxje.png"
                alt="chat bubble"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "10vh" }}></div>
      <div className="container is-max-widescreen">
        <div className="second reverse">
          <div className="mb-5 second-first">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675679206/Group_611_y5lsdu.png"
              alt="busy guy on laptop"
            />
          </div>
          <div className="second-second mb-5">
            <h2 className="mb-5 has-text-weight-bold blue-text">
              Encourage a <br />
              <span className="orange-text"> Data-Driven Culture</span>
              <br />
              with Self-Service Analytics
            </h2>

            <p className="pretrained mb-5">
              Promote the use of data to guide marketers in their
              decision-making with the support of a dedicated [AI] DATA
              Assistant with expertise in reporting and conversion rate
              optimization.
            </p>
          </div>
        </div>
      </div>
      <div className="blue-bg">
        <div className="container is-max-widescreen">
          <div className="second mb-5">
            <div className="second-second mb-5" style={{ flexGrow: 1 }}>
              <h3 className="mt-5 mb-3 has-text-weight-bold has-text-centered blue-text">
                <span className="orange-text">Timely Reporting</span> tailored
                to your needs
              </h3>
              <p
                className="has-text-centered mb-5"
                style={{ maxWidth: "700px", margin: "0 auto" }}
              >
                Having a dedicated [AI] Data Assistant working with each
                marketer knowing their focus, KPI’s and goals is a
                conversational AI solution developed to act as a member of your
                team.
              </p>

              <img
                style={{
                  maxWidth: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675680707/Chat_Bubble_Data_Assistant_3_1_liw47p.png"
                alt="chat bubble"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "10vh" }}></div>
      <div className="container is-max-widescreen">
        <div className="second">
          <div className="second-first mb-5">
            <h2 className="mb-5 has-text-weight-bold blue-text">
              <span className="orange-text">Analytics</span> Help Desk
              <br />
              and Ticketing
            </h2>

            <p className="pretrained mb-5">
              [AI] Data Assistant can be your first-line support solution for
              marketing data and analytics requests; passes on complex questions
              to your Analytics team for deeper analysis.
            </p>
          </div>
          <div className="mb-5 second-second">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675679546/Help_Desk_AI_3_1_g1vs13.png"
              alt="busy guy on laptop"
            />
          </div>
        </div>
      </div>

      <div className="blue-bg">
        <div className="container is-max-widescreen">
          <div className="second reverse">
            <div className="mb-5 second-first">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675681411/Notification_1_w7alvb.png"
                alt="busy guy on laptop"
              />
            </div>
            <div className="second-second mb-5">
              <h2 className="mb-5 has-text-weight-bold blue-text">
                Monitoring <span className="orange-text">and</span> Alerts
              </h2>

              <p className="pretrained mb-5">
                Automate the monitoring of your marketing goal performance by
                keeping track of crucial business KPIs and get alerts to notify
                you when and why changes happen.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container is-max-widescreen">
        <div className="second">
          <div className="second-first mb-5">
            <h2 className="mb-5 has-text-weight-bold blue-text">
              Easily share <span className="orange-text">Insight</span> <br />{" "}
              Across the Organization
            </h2>

            <p className="pretrained mb-5">
              Insights identified by your [AI] data assistant can be shared with
              individual colleagues or with the entire team or department to
              ensure everyone is focused on the same insight.
            </p>
          </div>
          <div className="mb-5 second-second">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675680505/Marketing_Team_-_Data_Assistant_1_1_zcibxn.png"
              alt="busy guy on laptop"
            />
          </div>
        </div>
      </div>
      <div className="blue-bg">
        <div className="container is-max-widescreen">
          <div className="second mb-5">
            <div className="second-second mb-5" style={{ flexGrow: 1 }}>
              <h3 className="mt-5 mb-3 has-text-weight-bold has-text-centered blue-text">
                Your next great{" "}
                <span className="orange-text">marketing campaign</span> is a
                conversation away
              </h3>
              <p
                className="has-text-centered mb-5"
                style={{ maxWidth: "700px", margin: "0 auto" }}
              >
                Upgrade your analytics game today with a self-service analytics
                tool, [AI] DATA Assistant will walk you through your marketing
                campaign.
              </p>

              <div className="choose mb-5">
                <div className="choose-card">
                  <img
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675680216/kisspng-digital-marketing-computer-icons-leadership-5b04acb9b23239_1_z7dnuy.png"
                    alt="learning"
                    className="mb-5"
                  />
                  <div className="orange-text has-text-weight-bold mb-3">
                    Marketing Managers
                  </div>
                  <p className="">
                    Make informed marketing choices by creating your own
                    data-driven stories and using those insights, rather than
                    relying on guesses or personal beliefs.
                  </p>
                </div>
                <div className="choose-card">
                  <img
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675680297/leader-icon-3_1_tu2yx4.png"
                    alt="learning"
                    className="mb-5"
                  />
                  <div className="orange-text has-text-weight-bold mb-3">
                    Frontline Marketers
                  </div>
                  <p className="">
                    Create tailored customer interactions at every touchpoint to
                    stand out in the crowd and retain customers through
                    exceptional experiences.
                  </p>
                </div>
                <div className="choose-card">
                  <img
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675680298/pngaaa_1_c6zmg2.png"
                    alt="learning"
                    className="mb-5"
                  />
                  <div className="orange-text has-text-weight-bold mb-3">
                    Analytics Team
                  </div>
                  <p className="">
                    Empower Marketers to accurately self-serve and independently
                    find answers, freeing up your time to focus on more
                    important projects, instead of spending it on report
                    generation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container is-max-widescreen">
        <div className="second reverse">
          <div className="mb-5 second-first">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675768872/WhatsApp_Image_2023-02-06_at_23.14.17-removebg-preview_s5wkkp.png"
              alt="busy guy on laptop"
            />
          </div>
          <div className="second-second mb-5">
            <h2 className="mb-5 has-text-weight-bold blue-text">
              Unlock insight from <br />
              your Marketing Technology stack
            </h2>

            <p className="pretrained mb-5">
              Have one source of truth by connecting and feeding your marketing
              technology data into one intelligent platform.
            </p>
            <a href="/demo" className="mb-4">
              <button
                className="spread-button orange-bg"
                style={{
                  color: "#fff",
                }}
              >
                Request a demo
              </button>
            </a>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "10vh" }}></div>
      <div className="blue-bg pb-5 pt-5">
        <div className="container is-max-widescreen p-3">
          <div>
            <h2 className="mt-5 mb-5 has-text-weight-bold has-text-centered blue-text">
              What <span className="orange-text">users</span> are saying
            </h2>
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="mySwiper"
              loop={true}
            >
              {data.map((f) => (
                <SwiperSlide key={f.title}>
                  <div>
                    <p
                      className="has-text-centered mb-5"
                      style={{
                        maxWidth: "800px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingBottom: "1rem",
                      }}
                    >
                      {f.body}
                    </p>
                    <p
                      className="orange-text has-text-centered mb-5 has-text-weight-bold is-italic"
                      style={{ paddingBottom: "2rem" }}
                    >
                      {f.title}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .swiper-button-next,
        .swiper-button-prev {
          color: var(--main-orange);
          margin-top: -100px;
        }
        .swiper-pagination-bullet-active {
          background-color: var(--main-blue);
        }

        .blue-bg {
          background-color: #f4f9ff;
        }
        .choose {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
          grid-gap: 2rem;
          justify-content: center;
        }
        .choose-card {
          background-color: #fff;
          border-radius: 8px;
          padding: 2rem;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
        }
        .choose-card p {
          font-size: 0.9rem;
        }
        .second.grad-box {
          background-image: linear-gradient(to right, #1a8aff, #0027b2);
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
        }
        .second.grad-box p {
          color: rgba(255, 255, 255, 0.7);
        }
        .image-grid {
          display: grid;
          grid-gap: 2rem;
          grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
          justify-content: center;
          padding-top: 3rem;
          padding-bottom: 3rem;
          align-items: center;
        }
        .image-grid img {
          margin-left: auto;
          margin-right: auto;
        }

        .blue-box {
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 20px;
        }
        .second {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
        }
        .second.school {
          max-width: 900px;
          margin-left: auto;
          margin-right: auto;
        }
        .second-first {
          flex-basis: 450px;
          flex-grow: 1;
          margin-right: 4rem;
        }
        .second-second {
          flex-basis: 450px;
          flex-shrink: 1;
          flex-grow: 1;
        }

        @media only screen and (min-width: 851px) {
          .overlay-cont {
            align-self: flex-end;
          }
          .overflow-img {
            transform: scale(1.6);
            transform-origin: bottom center;
          }
          .partner {
            margin-bottom: 5rem;
          }
        }
        @media only screen and (min-width: 1220px) {
          .sprawl {
            object-fit: cover;
            min-width: 650px;
          }
        }
        @media only screen and (max-width: 850px) {
          .second {
            flex-wrap: wrap;
            padding: 1rem;
          }
          .second-first {
            margin-right: 0px;
          }
          .second-second {
            flex-grow: 1;
            flex-basis: 200px;
          }
          .reverse {
            flex-direction: column-reverse;
          }
          .reverse .second-first {
            flex: 1;
          }
        }

        .second p {
          color: rgb(0, 0, 0, 0.8);
          line-height: 1.7;
        }
        .blue-box .pretrained {
          max-width: 500px;
          line-height: 1.7;
        }
        .blue-box h1 {
          max-width: 550px;
        }
        .home {
          margin-top: 65px;
        }
      `}</style>
    </main>
  );
}
