import { useState, useEffect } from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

const data = [
  {
    title: "Analytics Help Desk",
    body: "The [AI] Data Assistant platform is your first-line analytics support solution; it will take initial reporting questions and what it can’t answer will pass on to an Analyst on your team.",
  },
  {
    title: "Analytics Support Ticketing",
    body: "A dedicated service desk solution for digital marketing and analytics requests in the business. This gives your dedicated Analytics team greater control in managing requests for insight and reporting. ",
  },
  {
    title: "Single Analytics’ Solution",
    body: "Instead of relying on several platforms to gather your digital marketing analytics data, you can now use one single tool to intelligently query and access your data. With integrations into a wide range of digital analytics tools like Adobe Analytics, Google Analytics, Tealium AudienceStream, ObservePoint, BigQuery, AWS S3 and many others. ",
  },
  {
    title: "Data Access for Everyone",
    body: "Imagine an [AI] DATA Assistant assigned and dedicated to every user in your organization, focused on each user’s goals and KPIs, working as a collective force supporting teams and departments targets. This is a key benefit the [AI] DATA assistant gives a company data insight to everyone encouraging a culture for data-driven decision making. ",
  },

  {
    title: "Powerful Conversational Engine",
    body: "[AI] DATA Assistant is developed on a powerful conversation platform that allows users to simply ask their DATA Assistant questions and get answers in plain English. This makes relevant and timely information more accessible even to users who are less familiar with BI or analytics tools.",
  },
  {
    title: "Easily Share Insight Across the Organization",
    body: "Insights identified by your [AI] DATA Assistant can be shared with individual colleagues or with the entire team or department. This ensures everyone is focused on the same that is used to drive informed decision making across the organization.",
  },
  {
    title: "Analytics Training as you use",
    body: "Educate your organization on the use of data and analytics in decision making to be more data-driven. Training mode can be enabled on your [AI] DATA Assistant to teach users the fundamentals of data analysis from the beginner stage through to the advanced stage.",
  },
  {
    title: "Maximized Data Sources",
    body: "With [AI] DATA Assistant, businesses can connect their data sources (online and offline data) and enable users to search for and access information from any one of them directly from the platform. This lets companies give their users fast access to information any time they need them through the interface, email, Slack or voice commands.",
  },
  {
    title: "Data Governance and Access Control",
    body: "To ensure that only those with the proper authorizations can view sensitive company data, [AI] DATA Assistant allows administrators to stringently control who has access to which rows and columns and sections of data. On top of that, the solution also protects search suggestions according to these settings.",
  },
  {
    title: "Personalized Delivery",
    body: `Different customers have different usage patterns that inform how they prefer to receive notifications. Users in the [AI] DATA Assistant can determine the best channel—email, text, Slack, or in-app notification with the highest likelihood of getting the customer’s attention.`,
  },
];
const tips = [
  `A Team contains a maximum of 2 people. There is an extra cost for additional team members.`,
  `We have a glossary explanation that the system supplies. Every metric and dimension that is set up on the system has a definition and it can also provide recommendation.`,
  `Each Team contains 5 people and altogether 10 people for the 2 Teams. There is an extra cost for additional team members.`,
  `We have a glossary explanation that the system supplies. Every metric and dimension that is set up on the system has a definition and it can also provide recommendation.`,
  `Using historical data our algorithms aim to predict the outcomes of a target set for a marketing goal. It can be used to forecast trends and make informed decisions on how to improve the conversion rate of key performance indicators.`,
  `Our conversion rate optimization recommendation engine (in beta) offers suggestions for enhancing the conversion rate of crucial marketing metrics. It employs machine learning algorithms to examine data and present recommendations based on data patterns and trends.`,
  `Pro level support is available 24 hours a day, 7 days a week. Initial response times by issue priority are as follows: P0: 8 hours, P1: 1 day, P2: 2 days.`,
  `We have a glossary explanation that the system supplies. Every metric and dimension that is set up on the system has a definition and it can also provide recommendation.`,
  `Using historical data our algorithms aim to predict the outcomes of a target set for a marketing goal. It can be used to forecast trends and make informed decisions on how to improve the conversion rate of key performance indicators.`,
  `Our conversion rate optimization recommendation engine (in beta) offers suggestions for enhancing the conversion rate of crucial marketing metrics. It employs machine learning algorithms to examine data and present recommendations based on data patterns and trends.`,
  `The NLP Console is a valuable tool that provides insight into the workings of our natural language understanding system. With it, you can view how the system recognizes and interprets sentences or questions and can adjust as needed. It also lets you see what options the system has in generating a response.`,
  `Enterprise level support is available 24 hours a day, 7 days a week. Initial response times by issue priority are as follows: P0: 1 hour, P1: 2 hours, P2: 4 hours.`,
];
export default function Faqs() {
  const [currentTab, setCurrentTab] = useState("");
  useEffect(() => {
    const clickables = document.querySelectorAll(".is-clickable");
    clickables.forEach((c, index) => {
      tippy(c, {
        content: `<div class="tip">${tips[index]}</div>`,
        allowHTML: true,
        theme: "light",
      });
    });
  }, []);
  return (
    <main className="home">
      <div className="container is-max-widescreen p-2">
        <div style={{ marginBottom: "10vh" }}></div>
        <h1 className="mb-5 has-text-weight-bold blue-text has-text-centered">
          Plans Tailored To Your{" "}
          <span className="orange-text">Martech Growth</span>
        </h1>
        <div style={{ marginBottom: "10vh" }}></div>
        <div className="choose mb-5">
          <div className="choose-card">
            <h4 className="mb-4 has-text-weight-bold blue-text is-3 title">
              Starter
            </h4>

            <a href="/demo" className="mb-4 mt-4">
              <button
                className="spread-button orange-bg"
                style={{
                  color: "#fff",
                  width: "100%",
                }}
              >
                Request a demo
              </button>
            </a>
            <ul className="price-list mt-4">
              <li className="is-clickable has-text-weight-semibold is-underlined">
                1 Team
              </li>
              <li>Self-serve analytics companion</li>
              <li>Productivity and collaboration insight reporting tool</li>
              <li>KPIs – Monitoring, Goals</li>
              <li>Goal notifications and push messages</li>
              <li>Notifications and Alerts</li>
              <li>Ticketing Systems</li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Metrics and Dimension Definitions
              </li>
              <li>Integration Google Analytics</li>
              <li>Access via Web and Email</li>
              <li>Community based support</li>
            </ul>
          </div>
          <div className="choose-card growth">
            <h4 className="mb-4 has-text-weight-bold  is-3 title has-text-white">
              Growth
            </h4>

            <a href="/demo" className="mb-4 mt-4">
              <button
                className="spread-button"
                style={{
                  color: "var(--main-orange)",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                Request a demo
              </button>
            </a>
            <ul className="price-list mt-4">
              <li className="is-clickable has-text-weight-semibold is-underlined">
                2 Teams
              </li>
              <li>Self-serve analytics companion</li>
              <li>Productivity and collaboration insight reporting tool</li>
              <li>KPIs – Monitoring, Goals</li>
              <li>Goal notifications and push messages</li>
              <li>Notifications and Alerts</li>
              <li>Ticketing Systems</li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Metrics and Dimension Definitions
              </li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Predictive Modelling
              </li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Recommendation engine
              </li>
              <li>Calls integration (Zoom and Teams)</li>
              <li>Document distribution</li>
              <li>Integration with Adobe Analytics and Google Analytics</li>
              <li>
                Access Anywhere via Web, Email, WhatsApp, Teams, Slack, SMS,
                Voice
              </li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Pro level support
              </li>
            </ul>
          </div>
          <div className="choose-card">
            <h4 className="mb-4 has-text-weight-bold blue-text is-3 title">
              Enterprise
            </h4>

            <a href="/demo" className="mb-4 mt-4">
              <button
                className="spread-button orange-bg"
                style={{
                  color: "#fff",
                  width: "100%",
                }}
              >
                Request a demo
              </button>
            </a>
            <ul className="price-list mt-4">
              <li>Unlimited Teams</li>
              <li>Self-serve analytics companion</li>
              <li>Productivity and collaboration insight reporting tool</li>
              <li>KPIs – Monitoring, Goals</li>
              <li>Goal notifications and push messages</li>
              <li>Notifications and Alerts</li>
              <li>Ticketing Systems</li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Metrics and Dimension Definitions
              </li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Predictive Modelling
              </li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Recommendation engine
              </li>
              <li>Calls integration (Zoom and Teams)</li>
              <li>Document distribution</li>
              <li>Integration with Adobe Analytics and Google Analytics</li>
              <li>GPT3 Integration</li>
              <li>Document Emailing</li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                NLP Console
              </li>
              <li>
                Integration with Adobe Analytics, Google Analytics, Tealium, and
                Observepoint
              </li>
              <li>DoubleClick</li>
              <li>Facebook Business Manager</li>
              <li>Connect any data source via APIs, webhooks, CSV files</li>
              <li className="is-clickable has-text-weight-semibold is-underlined">
                Enterprise support
              </li>
            </ul>
          </div>
        </div>
        {/* <div style={{ marginBottom: "15vh" }}></div>
        <div className="second">
          <div className="blue-box mb-2 second-first">
            <h1 className="mb-5 has-text-weight-bold blue-text mt-5">
              Benefits of Data <span className="orange-text">Assistant</span>
            </h1>
          </div>
        </div> */}
      </div>

      {/* <div className="container is-max-widescreen p-2">
        <div className="faqs">
          {data.map((d) => (
            <div
              className="expandable"
              key={d.title}
              onClick={() => {
                if (currentTab === d.title) {
                  return setCurrentTab("");
                }
                setCurrentTab(d.title);
              }}
            >
              <div className="expandable-header">
                <p className="blue-text has-text-weight-bold">{d.title}</p>
                <p className="icon">
                  <i
                    className={`${
                      currentTab === d.title
                        ? "fa-solid fa-minus"
                        : "fa-solid fa-plus"
                    } orange-text`}
                  ></i>
                </p>
              </div>
              <p
                className={` ${
                  currentTab === d.title ? "expand" : ""
                } expandable-body`}
              >
                {d.body}
              </p>
            </div>
          ))}
        </div>
      </div> */}
      <style jsx="true">
        {`
        .tip {
          padding: 1rem;
        }
        .price-list li::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 1px;
          top: 2px;
          height: 15px;
          width: 25px;
          background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1675685567/Check_ivrwsx.png");
          background-position: left 5px;
          background-repeat: no-repeat;
          background-size: 15px;
        }
        .price-list li {
          padding-bottom: 1rem;
          padding-left: 25px;
          position: relative;
        }
        .choose {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          grid-gap: 2rem;
          justify-content: space-between;
        }

        .choose-card {
          background-color: #fff;
          border-radius: 20px;
          padding: 2rem;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
        }
        .choose-card p {
          font-size: 0.9rem;
        }

        .choose-card.growth {
          background-color: var(--main-orange);
          color: #fff;
        }
        .expandable-body {
          height: 0px;
          overflow: hidden;
          transition: 0.3s ease;
          margin-bottom: 1rem;
          line-height: 1.6;
        }
        .expandable-body.expand {
          height: auto;
          margin-top: 0.8rem;
        }
        .expandable {
          transition: 0.3s ease;
          border-bottom: 1px solid #eee;
          cursor: pointer;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
        }
        .expandable p {
          font-size: 0.9rem;
        }
        .expandable-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          padding-top: 1rem;
        }
        .home {
          margin-top: 65px;
        }
        .expandable-header > div {
          flex-basis: 100px;
        }
        .expandable-header .icon {
          margin-left: 1rem;
          flex-shrink: 0;
        }
      `}
      </style>
    </main>
  );
}
